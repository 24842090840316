
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class InvoiceBadgeStatus extends Vue {
  @Prop()
  public status!: string

  public get statusValue(): string {
    return this.status.replaceAll('_', ' ')
  }

  public statusList = {
    default: {
      color: 'info',
    },
    draft: {
      color: 'secondary',
    },
    overdue: {
      color: 'danger',
    },
    not_found: {
      color: 'warning',
    },
    processed: {
      color: 'success',
    },
    sent: {
      color: 'success',
    },
    paid: {
      color: 'success',
    },
    ready: {
      color: 'info',
    },
    upload_error: {
      color: 'danger',
    },
    error: {
      color: 'danger',
    },
    send_error: {
      color: 'danger',
    },
  }
}
