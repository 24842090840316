
import ViewModel from '@/models/ViewModel'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'

@Component({
  components: {
    SelectPicker,
  },
})
export default class MediaPlanPicker extends ViewModel {
  @Prop()
  public value!: string[] | string | null

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public select_plan_items!: boolean

  @Prop()
  public id_options!: any

  @Prop({ default: false })
  public show_number!: boolean

  public ready: boolean = false

  public busy: boolean = false

  public update: boolean = false

  public options: any = []

  public local_value: string[] | string | null = []

  public selected_opt: any = null

  @Watch('value')
  public onChangeProp(val: any) {
    if (this.update) {
      if (val == null) {
        this.local_value = null
      } else {
        let has_options = false
        if (Array.isArray(this.value)) {
          has_options = true
          this.value.forEach((v: any) => {
            if (!this.options.some((o: any) => o.value === v)) {
              has_options = false
            }
          })
        }
        if (
          (Array.isArray(this.value) && this.value.length > 0 && !has_options)
          || typeof this.value === 'string'
        ) {
          let value = Array.isArray(this.value) ? this.value : [this.value]
          let opts = null
          if (this.select_plan_items) {
            opts = MediaPlanItem.searchOptions({
              value,
            })
          } else {
            opts = MediaPlan.searchOptions({
              value,
            })
          }

          opts.then(response => {
            this.options = response
            this.busy = false
            this.local_value = this.value
            this.ready = true
          })
        } else {
          this.local_value = this.value
        }
      }
    }
    this.update = true
  }

  @Watch('local_value')
  public onChangeValue(val: any) {
    this.update = false
    this.$emit('input', val)
  }

  public get selected_option() {
    return this.local_value
  }

  public set selected_option(data) {
    if (data) {
      this.$emit('input', data)
    }
  }

  public searchOptions(search = '*') {
    this.busy = true
    if (!search) search = '*'
    let opts: any = null
    if (this.id_options && this.id_options.length) {
      // it contains limited options, only the id_options are the permitted options
      this.busy = false
      return
    }
    if (this.select_plan_items) {
      opts = MediaPlanItem.searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
    } else {
      opts = MediaPlan.searchOptions({
        search: search.includes('*') ? search : `*${search}*`,
      })
    }

    opts.then((response: any) => {
      this.options = response
      this.busy = false
      this.ready = true
    })
  }

  public open() {
    if (this.options.length == 0) this.searchOptions()
  }

  public mounted() {
    this.busy = true
    let opts: any = null

    if (this.id_options && this.id_options.length) {
      this.setOptions(this.id_options)
      return
    }

    if (!this.value || (Array.isArray(this.value) && this.value.length == 0)) {
      this.ready = true
      this.busy = false
    } else {
      this.busy = true
      setTimeout(() => {
        if (this.select_plan_items) {
          opts = MediaPlanItem.searchOptions({
            value: this.value,
          })
        } else {
          opts = MediaPlan.searchOptions({
            value: this.value,
          })
        }

        opts.then(response => {
          this.options = response
          this.selected_opt = response.find((o: any) => String(o.value) === String(this.value))
          this.busy = false
          this.ready = true
        })
      }, 200)
    }
  }

  public setOptions(values: Array<any> = []) {
    MediaPlan.searchOptions({
      value: values,
    }).then(response => {
      this.options = response
      this.selected_opt = response.find((o: any) => String(o.value) === String(this.value))
      this.busy = false
      this.ready = true
    })
  }
}
