
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import Task from '@/models/Task'

@Component({
  components: {
    UserPicker,
  },
})
export default class TaskOwnerSelector extends ViewModel {
  @Ref() public wrapper!: any

  @Prop({ required: true })
  public item!: any

  @Prop({ required: false })
  public disabled!: boolean

  @Prop({ required: true })
  public targetKey!: any

  public owner: any = ''

  public openPopOver: boolean = false

  public loading: boolean = false

  public id: any = this.randomUUID()

  public getInitials(name: string) {
    if (!name) return

    const nameParts = name.trim().split(' ')

    const firstInitial = nameParts[0].charAt(0)

    const secondInitial = nameParts.length > 1 ? nameParts[1].charAt(0) : ''

    const initials = firstInitial + secondInitial

    return initials.toUpperCase()
  }

  public get target_name() {
    if (this.targetKey.includes('owner')) {
      return this.item.owner.name
    }
    return this.item.target.name
  }

  public mounted() {
    this.owner = this.item[this.targetKey]

    this.$root.$on('bv::popover::show', (bvEventObj: any) => {
      if (bvEventObj.componentId !== `user-popover-${this.item.id}-${this.id}`) {
        this.openPopOver = false
      }
    })
  }

  public toggle() {
    this.openPopOver = !this.openPopOver
  }

  public update() {
    this.loading = true
    let updated: Task = Task.toObject(this.item)

    updated[this.targetKey] = this.owner

    updated.save().then(() => {
      this.$emit('saved')
      this.openPopOver = false
      this.loading = false
    })
  }
}
