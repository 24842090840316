
import FormInput from '@/components/FormInput/FormInput.vue'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanItem from '@/models/MediaPlanItem'
import ViewModel from '@/models/ViewModel'
import Component from 'vue-class-component'
import FormBuilder from '@/components/DataForm/FormBuilder.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import {
  BillingCycle,
  capitalize,
  MediaPlanTypes,
  MediaPlanViewColumns,
  MediaPlanViewColumnsLinear,
  AgencyFeeModel,
  InvoicePaymentTerms,
  InvoiceBillingTarget,
  InvoiceUploadMode,
  OrderTypeOptions,
  ImpressionModelOptions,
  CreativeLength,
  DaysOfWeek,
} from '@/models/interface/Common'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import { percentageMask, currencyMask, integerMask } from '@/models/interface/Masks'
import TermsPicker from '@/components/TermsPicker/TermsPicker.vue'
import DemoTargetBuilder from '@/components/DemoTargetBuilder/DemoTargetBuilder.vue'
import CompanyBillingInformation from '@/components/CompanyBillingInformation/CompanyBillingInformation.vue'
import countryCodes from '@/data/country_options'
import stateCodes from '@/data/state_options'
import CustomPicker from '@/components/CustomPicker/CustomPicker.vue'
import Api from '@/models/Api'
import CheckboxInput from '@/components/CheckboxInput/index.vue'
import SelectOption from '@/models/interface/SelectOption'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'

@Component({
  components: {
    FormInput,
    FormBuilder,
    SelectPicker,
    CompanyPicker,
    UserPicker,
    TermsPicker,
    DemoTargetBuilder,
    CompanyBillingInformation,
    CustomPicker,
    CheckboxInput,
    DatePicker,
    MediaPackagePicker,
  },
})
export default class MediaPlanBatchEdit extends ViewModel {
  public selected: string[] = []

  public local_media_plan: MediaPlan = new MediaPlan()

  public batch_item: MediaPlanItem = new MediaPlanItem()

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public mediaplan_types: any = MediaPlanTypes

  public percentage_mask: any = percentageMask

  public currency_mask: any = currencyMask

  public integer_mask: any = integerMask

  public billing_cycle_options: any = BillingCycle

  public view_columns_options: any = MediaPlanViewColumns

  public view_linear_columns_options: any = MediaPlanViewColumnsLinear

  public agency_fee_model: any = AgencyFeeModel

  public invoice_payment_terms: any = InvoicePaymentTerms

  public invoice_billing_targets: any = InvoiceBillingTarget

  public invoice_upload_modes: any = InvoiceUploadMode

  public country_codes: any = countryCodes

  public state_codes: any = stateCodes

  public capitalize: any = capitalize

  public uploadMode() {
    const api = new Api()
    api
      .get(`media_plan/upload_mode/${this.local_media_plan.metadata.agency.id}`)
      .then((response: any) => {
        this.local_media_plan.metadata.invoice_mode = response.data.result.upload_mode
        if (
          response.data.result.name
          && (!this.local_media_plan.metadata.agency.name
            || this.local_media_plan.metadata.agency.name === '')
        ) {
          this.local_media_plan.metadata.agency.name = response.data.result.name
        }
        if (
          response.data.result.address
          && (!this.local_media_plan.metadata.agency.address_line_1
            || this.local_media_plan.metadata.agency.address_line_1 === '')
        ) {
          this.local_media_plan.metadata.agency.address_line_1 = response.data.result.address[0] ?? ''
          this.local_media_plan.metadata.agency.address_line_2 = response.data.result.address[1] ?? ''
          this.local_media_plan.metadata.agency.address_line_3 = response.data.result.address[2] ?? ''
          this.local_media_plan.metadata.agency.address_line_4 = response.data.result.address[3] ?? ''
        }
      })
  }

  public get options() {
    return {
      order_type: OrderTypeOptions,
      impression_model: ImpressionModelOptions,
      demo_targets: this.local_media_plan.metadata.demo_targets.map(
        (d: any, index: number) => new SelectOption(`${d.target}${d.age_low}${d.age_high}`, index),
      ),
      creative_length: CreativeLength,
      daysOfWeek: DaysOfWeek,
    }
  }

  public buildData() {
    this.local_media_plan.line_items = []
    this.local_media_plan.line_items.push(this.batch_item)
    return { selected: this.selected, media_plan: this.local_media_plan }
  }
}
