
import Opportunity from '@/models/Opportunity'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CrmProgress extends ViewModel {
  @Prop()
  public value!: any

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: '' })
  public mainPlan!: string

  @Prop()
  public progressStatus!: any

  public opportunity_status: any = Opportunity.opportunity_status

  public updateOnClick(clicked_status: any) {
    let automated_status = ['won', 'lost']
    let blocked_status = ['won']
    let blocked_without_media_plan = ['pitched', 'consideration', 'io_sent']

    if (automated_status.includes(this.progressStatus) && this.mainPlan) {
      WebMessage.warning(
        'The status can not be updated, it will be automatically updated with the media plan.',
      )
      return
    }

    if (blocked_status.includes(clicked_status.code)) {
      WebMessage.warning(
        'You can not set the status above IO Sent, the status will be automatically updated once the media plan is confirmed or canceled.',
      )
      return
    }

    if (blocked_without_media_plan.includes(clicked_status.code) && !this.mainPlan) {
      WebMessage.warning(
        'You can not set the status above Targeting without a Media Plan',
      )
      return
    }

    this.$emit('input', clicked_status.value)
    this.$emit('update:progressStatus', clicked_status.code)
  }

  public lowerCase(s: any) {
    if (!s) return ''
    return s.toLowerCase()
  }
}
