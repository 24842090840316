
import Task from '@/models/Task'
import ViewModel from '@/models/ViewModel'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StatusSelector extends ViewModel {
  @Prop({ required: true })
  public item!: any

  @Prop({ required: false })
  public disabled!: boolean

  public openPopOver: boolean = false

  public loading: boolean = false

  public id: any = this.randomUUID()

  public stauts_list = [
    {
      name: 'In Progress',
      value: 'in_progress',
      color: 'info',
    },
    {
      name: 'Hold',
      value: 'hold',
      color: 'warning',
    },
    {
      name: 'Pending',
      value: 'pending',
      color: 'secondary',
    },
    {
      name: 'Completed',
      value: 'completed',
      color: 'success',
    },
  ]

  public updateStatus(status: string) {
    this.loading = true
    let updated: Task = Task.toObject(this.item)

    updated.completed_by_id = null

    updated.completed_at = null

    updated.status = status

    this.$emit('update:item', updated)

    updated.save().then(res => {
      this.loading = false
      this.$emit('saved')
      this.openPopOver = false
    })
  }

  public mounted() {
    this.$root.$on('bv::popover::show', (bvEventObj: any) => {
      if (bvEventObj.componentId !== `status-popover-${this.item.id}-${this.id}`) {
        this.openPopOver = false
      }
    })
  }
}
