
import PageHeader from '@/components/PageHeader/PageHeader.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import MediaPlan from '@/models/MediaPlan'
import IconAction from '@/components/IconAction/IconAction.vue'
import DataTable from '@/components/DataTable/index.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import Opportunity from '@/models/Opportunity'

import CompanyContactPicker from '@/components/CompanyContactPicker/CompanyContactPicker.vue'
import FooterNav from '@/components/FooterNav/FooterNav.vue'
import PrePlanTable from '@/pages/PrePlan/components/PrepPlanTable.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import MediaPlanTable from '@/pages/Sales/MediaPlan/components/MediaPlanTable.vue'
import Company from '@/models/Company'
import CrmProgress from '@/components/ProgressBar/CrmProgress.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import WebMessage from '@/models/WebMessage'
import { clone, initial } from 'lodash'
import moment from 'moment'
import Targetting from '@/models/BasicTargetting'
import MediaPackage from '@/models/MediaPackage'
import { EventBus } from '@/plugins/eventBus'
import { RegionGroupMap } from '@/models/interface/Common'
import { currencyMask } from '@/models/interface/Masks'
import Task from '@/models/Task'
import numeral from 'numeral'
import AuditHistory from '@/components/AuditHistory/AuditHistoryTable.vue'
import AuditProvider from '@/components/AuditHistory/AuditProvider.vue'
import OpportunityForm from './components/OpportunityForm.vue'
import ActivityTab from './components/ActivityTab.vue'
import PlanRequest from './components/PlanRequest.vue'
import InvoiceSimpleTable from '../Invoice/Components/InvoiceSimpleTable.vue'
import EditTask from '../Task/components/EditTask.vue'
import MainPlanTab from './components/MainPlanTab.vue'
import TaskTable from '../Task/components/TaskTable.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    PageHeader,
    Widget,
    FormInput,
    IconAction,
    DataTable,
    CompanyPicker,
    UserPicker,
    CompanyContactPicker,
    OpportunityForm,
    FooterNav,
    PrePlanTable,
    SelectPicker,
    MediaPlanTable,
    ActivityTab,
    CrmProgress,
    PlanRequest,
    ModelFileUploader,
    InvoiceSimpleTable,
    MainPlanTab,
    TaskTable,
    AuditHistory,
    AuditProvider,
  },
})
export default class OpportunityEdit extends ViewModel {
  @Ref() public request_plan!: PlanRequest

  @Ref() public opportunityFormRef!: OpportunityForm

  @Ref() public audit_history!: AuditHistory

  @Ref() readonly tabs!: any

  public opportunity: Opportunity = new Opportunity()

  public pre_edit_opportunity: Opportunity = new Opportunity()

  public tabIndex: number = 0

  public modals = {
    cancel: false,
  }

  public toggle: any = {
    participants: true,
    products: false,
    media_plans: false,
    files: true,
  }

  public ref: any = 'opportunities'

  public is_editing: boolean = true

  public block_editing: boolean = false

  public contact_logs: any = []

  public media_plan: MediaPlan | null = null

  public busy: boolean = false

  public records: number = 0

  public new_amount: number = 0

  public ready: boolean = false

  public add_opportunity: boolean = false

  public company: Company | null = null

  // Hide footer
  public hide_footer: boolean = false

  public tabs_count: number = 0

  @Watch('tabIndex')
  public onTabChange(value: any) {
    if (this.tabs_count - 1 === value) {
      this.viewHistory(this.opportunity)
    }
  }

  @Watch('agencyId')
  public onAgencyChange(value: any) {
    this.getCompany()
  }

  @Watch('oppotunity.types')
  public onTypeChange(value: any) {
    if (!this.opportunity.id) {
      if (this.opportunity.types.includes('ccl') && this.opportunity.types.includes('ssl')) {
        this.opportunity.account_manager_id = this.region_map.national_both
      } else if (this.opportunity.types.includes('ccl')) {
        this.opportunity.account_manager_id = this.region_map.national_ccl
      } else if (this.opportunity.types.includes('ssl') && this.user.region == 'national') {
        this.opportunity.account_manager_id = this.region_map.national_ssl
      } else {
        this.opportunity.account_manager_id = this.region_map[this.user.region]
      }
    }
  }

  public task_table_query: any = null

  private get region_map() {
    return RegionGroupMap
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public get agencyId() {
    return this.opportunity.agency_id
  }

  public get isEditing() {
    return !!this.$route.params.id
  }

  public get canRequest() {
    if (this.$route.query.action && this.$route.query.action === 'new') return true
    return this.opportunity.agency_id && this.opportunity.agency_id.length > 0
  }

  public edit() {
    this.pre_edit_opportunity = Opportunity.toObject(this.opportunity)
    // this.is_editing = true
  }

  public cancelEdit() {
    this.checkDirtyRecords(() => {
      this.opportunity = Opportunity.toObject(this.pre_edit_opportunity)
      // this.is_editing = false
    })
  }

  public cancelModal() {
    this.hide_footer = false
  }

  public cancelOrder() {
    this.modals.cancel = true
    this.hide_footer = true
  }

  public confirmCancelOrder() {
    this.opportunity.is_cancelled = true
    if (!this.opportunity.original_amount) {
      this.opportunity.original_amount = clone(this.opportunity.amount)
    }
    this.modals.cancel = false
    this.hide_footer = false

    let task = new Task()
    task.name = `Cancel Opportunity: ${this.opportunity.name}`
    task.additional_information.additional_notes = `<strong>Cancel Opportunity/Media Plan:</strong> ${this.opportunity.name}<br/>`
    task.additional_information.additional_notes += `<strong>Reason:</strong> ${this.opportunity.close_reason}<br/>`
    task.additional_information.additional_notes += `<strong>Original Amount:</strong> ${numeral(
      this.opportunity.original_amount,
    ).format('$0.00')}<br/>`
    task.additional_information.additional_notes += `<strong>New Amount:</strong> ${numeral(
      this.new_amount,
    ).format('$0.00')}<br/>`
    task.owner_model_id = this.opportunity.id
    task.owner_model_type = 'App\\Models\\Opportunity'
    task.type = 'cancel_opportunity'
    task.target_id = this.opportunity.account_manager_id
    task.additional_information.advertiser_id = this.opportunity.advertiser_id
    task.additional_information.agency_id = this.opportunity.agency_id
    this.opportunity.save().then(() => {
      task.save()
    })
  }

  public createTask() {
    if (!this.opportunity.id) {
      WebMessage.confirm(
        'You must save the opportunity before creating a task. Do you want to save it now?',
        'Save Opportunity',
        {
          okTitle: 'Save',
          cancelTitle: 'Cancel',
        },
      ).then((save: boolean) => {
        if (save) {
          this.saveOpportunity().then(() => {
            this.createTask()
          })
        }
      })
      return
    }
    this.hide_footer = true
    let Component = Vue.extend(EditTask)
    let initialDueDate = moment().add(1, 'week').endOf('day')
    // if due date is on weekend, set it to monday
    if (initialDueDate.day() === 0) {
      initialDueDate = initialDueDate.add(1, 'day').endOf('day')
    } else if (initialDueDate.day() === 6) {
      initialDueDate = initialDueDate.add(2, 'day').endOf('day')
    }

    const flight_start: any = moment(this.opportunity.start_at).format('YYYY-MM-DD')
    const flight_end: any = moment(this.opportunity.end_at).format('YYYY-MM-DD')

    let instance = new Component({
      propsData: {
        initialPayload: {
          type: 'request_media_plan',
          name: `Opportunity: ${this.opportunity.name}`,
          target_id: this.opportunity.account_manager_id,
          owner_model_type: 'App\\Models\\Opportunity',
          owner_model_id: this.opportunity.id,
          additional_information: {
            products: [...this.opportunity.types],
            targetting: new Targetting(),
            demo_groups: [{ age_low: '25', age_high: '54', target: 'AD' }],
            amount: this.opportunity.amount,
            agency_id: this.opportunity.agency_id,
            advertiser_id: this.opportunity.advertiser_id,
            flight_start,
            flight_end,
          },
        },
      },
    })
    instance.$mount()

    instance.$on('close', () => {
      this.hide_footer = false
    })
  }

  public checkBeforeSave() {
    let start_at = moment(this.opportunity.start_at)
    let end_at = moment(this.opportunity.end_at)
    if (end_at.isBefore(start_at)) {
      WebMessage.error('End date must be after start date')
      return false
    }
    if (['win', 'lost'].includes(this.opportunity.status) && !this.opportunity.close_reason) {
      this.opportunityFormRef.askReason()
      return false
    }
    return true
  }

  public saveOpportunity() {
    if (this.checkBeforeSave()) {
      this.loading = true
      return this.opportunity
        .save()
        .then((res: any) => {
          this.loading = false
          const old_id = this.opportunity.id
          this.opportunity = Opportunity.toObject(res.data.result)
          this.pre_edit_opportunity = Opportunity.toObject({ ...this.opportunity })
          if (!this.isEditing && res.data.result.id && !old_id) {
            this.$router.push({ name: 'opportunity', params: { id: res.data.result.id } })
            setTimeout(() => {
              this.ready = true
            }, 200)
          }

          this.ready = false
          setTimeout(() => {
            this.ready = true
          }, 200)
          // this.is_editing = false
        })
        .catch((err: any) => {
          this.loading = false

          throw err
        })
    }
    return Promise.reject()
  }

  public checkDirtyRecords(
    callback: any,
    message: string = 'You have unsaved changes, if you proceed you might lose your changes. Do you want to save it first?',
  ) {
    // check if dirty
    if (this.hasUnsavedUpdated) {
      return WebMessage.confirm(message, 'Unsaved Items', {
        okTitle: 'Save',
        cancelTitle: 'Discard',
      }).then((save: boolean) => {
        if (save) {
          this.saveOpportunity().then(() => (callback ? callback() : Promise.resolve()))
        } else {
          return callback ? callback() : Promise.resolve()
        }
      })
    }
    return callback ? callback() : Promise.resolve()
  }

  public get hasUnsavedUpdated() {
    return this.opportunity.is_dirty
  }

  public get breadcrumb_path() {
    let station = `${this.opportunity.station?.name} / `
    if (this.opportunity.station) station = `${this.opportunity.station?.name} / `

    let advertiser = ''
    if (this.opportunity.advertiser) advertiser = `${this.opportunity.advertiser?.name} / `

    let name = ''
    if (this.opportunity.name) name = this.opportunity?.name

    let agency = ''
    if (this.opportunity.agency && this.opportunity.agency) {
      agency = `${this.opportunity.agency?.name} / `
    }

    let path = `${agency || ''}${station || ''}${advertiser || ''}${name || ''}`

    return path || '-'
  }

  public getProp(obj: any = null, prop: any, separator: string = ' / ') {
    if (!obj) return ''
    return obj[prop] ? obj[prop] + separator : ''
  }

  public mounted() {
    /* this.is_editing = !this.$route.params.hasOwnProperty('id')
      || (this.$route.params.hasOwnProperty('id') && !this.$route.params.id)
      || this.$route.query.hasOwnProperty('edit')
      */
    this.tabs_count = this.tabs.getTabs().length
    EventBus.$on('open-task-modal', (open: boolean) => {
      this.hide_footer = open
    })
  }

  public loadData() {
    if (this.isEditing && !this.$route.query.action) {
      this.loading = true
      Opportunity.find(this.$route.params.id).then(response => {
        // @ts-ignore
        this.opportunity = null
        this.opportunity = response
        if (response.media_plan_ids && response.media_plan_ids.length > 0) {
          this.block_editing = true
        }
        this.task_table_query = [`owner_model_id:${this.opportunity.id}`]

        this.getCompany(response.agency_id)
        this.loading = false
        this.ready = true
      })
    } else {
      this.opportunity.account_manager_id = this.region_map.east

      if (this.user.region && this.region_map[this.user.region]) {
        this.opportunity.account_manager_id = this.region_map[this.user.region]
      }

      if (this.$route.query.action === 'new') {
        // @ts-ignore
        this.opportunity[`${this.$route.query.type}_id`] = this.$route.params.id
      }

      if (this.$route.params.id) {
        this.getCompany(this.$route.params.id)
          .then(() => {
            this.loading = false
            this.ready = true
          })
          .catch(() => {
            this.loading = false
            this.ready = true
          })
      } else {
        this.loading = false
        this.ready = true
      }
    }
  }

  public created() {
    this.loadData()

    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }
  }

  private beforeDestroy() {
    EventBus.$off('open-task-modal')
  }

  public beforeRouteLeave(to: object, from: object, next: any) {
    if (!this.hasUnsavedUpdated) {
      next()
    } else {
      this.checkDirtyRecords(() => {
        next()
      })
    }
  }

  public async getCompany(id: any = null) {
    return Company.find(id || this.opportunity.agency_id).then(response => {
      this.company = response
    })
  }

  public requestNewPlan(type: string) {
    this.request_plan.openModal(type)
  }

  public disableEdition(e: boolean) {
    if (e) {
      this.block_editing = true
    }
  }

  public setNewMainPlan() {
    this.tabIndex = 0

    setTimeout(() => {
      const element = document.getElementById('main-media-plan-picker')
      if (element) element.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  }

  public viewHistory(opportunity: Opportunity) {
    // this.audit_history.setModel(opportunity)
    // Vue.set(this.audit_history, 'ready', true)
    // Vue.set(this.audit_history, 'history_loading', false)
    // this.audit_history.refresh(300)
  }
}
