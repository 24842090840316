import router from '@/Routes'
import PrePlan from '@/models/PrePlan'
import SystemtModule from '@/store/SystemModule'
import { getModule } from 'vuex-module-decorators'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
    show: true,
    type: 'checkbox',
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'agency_id',
    label: 'Agency',
    sortable: true,
    show: true,
    class: 'text-center align-middle text-capitalize',
  },
  {
    key: 'action',
    label: 'Actions',
    filter: false,
    sortable: false,
    show: true,
    class: 'text-center align-middle text-capitalize',
    type: 'action_list',
    actions: [
      {
        icon: 'pencil',
        title: 'Edit',
        event: 'edit',
        top_level: true,
        show: () => user && user.can('max_avails_plan', 'edit'),
        action: (prePlan: PrePlan) => {
          router.push({ name: 'preplan', params: { id: prePlan.id } })
        },
      },
      {
        icon: 'clone',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('max_avails_plan', 'edit'),
        action: (prePlan: PrePlan) => {
          router.push({
            name: 'preplan',
            params: { id: prePlan.id },
            query: { action: 'clone' },
          })
        },
      },
      {
        icon: 'file-download',
        title: 'Export',
        event: 'download',
      },
      {
        icon: 'las la-exchange-alt',
        title: 'Generate media plan',
        event: 'convert',
        top_level: true,
        action: (prePlan: PrePlan) => {
          router.push({
            name: 'preplan-convert',
            params: { id: prePlan.id },
          })
        },
      },
    ],
  },
]
