
import FormInput from '@/components/FormInput/FormInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import ViewModel from '@/models/ViewModel'
import moment from 'moment'

import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import OpportunityActivity from '@/models/OpportunityActivity'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectOption from '@/models/interface/SelectOption'
import Widget from '@/components/Widget/Widget.vue'
import CompanyContactPicker from '@/components/CompanyContactPicker/CompanyContactPicker.vue'
import { startCase } from 'lodash'

@Component({
  components: {
    FormInput,
    IconAction,
    SelectPicker,
    DatePicker,
    Widget,
    CompanyContactPicker,
  },
})
export default class ActivityTab extends ViewModel {
  @Prop({ default: '', required: true })
  public target!: string

  @Prop({ default: '', required: true })
  public targetValue!: string

  @Prop({ default: false })
  public noEdit!: boolean

  @Prop({ default: false })
  public showOwner!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  public loading: boolean = false

  // not used
  // @Prop({ default: [] })
  // public value!: any

  public UPDATE: number = 0

  public local_value: OpportunityActivity[] = []

  @Watch('local_value')
  public onLocalValueChange() {
    this.$emit('input', this.local_value)
  }
  // public local_value: any = []

  public is_creating: boolean = false

  public is_editing: boolean = false

  public temp_activity: OpportunityActivity = new OpportunityActivity()

  public editing_note_index: number = -1

  public temp_note: any = {
    name: null,
    content: null,
    type: 'note',
    temp_id: null,
    delete_on_save: false,
  }

  // Default table pagination options
  public pagination_options: any = [
    { name: '10', value: '10' },
    { name: '25', value: '25' },
    { name: '50', value: '50' },
    { name: '100', value: '100' },
  ]

  public opportunity_types: any = [
    {
      name: 'Call',
      value: 'call',
    },
    {
      name: 'Meeting',
      value: 'meeting',
    },
    {
      name: 'Email',
      value: 'email',
    },
    {
      name: 'Other',
      value: 'other',
    },
  ]

  public perPage: number = 25

  public currentPage: number = 1

  public sortBy: string = 'created_at'

  public sortDesc: boolean = true

  public filter_mode: string = 'inclusive'

  public records: number = 0

  public sub_types: SelectOption[] = [
    new SelectOption('Introduction/Capabilities', 'introduction'),
    new SelectOption('Follow-Up', 'follow-up'),
    new SelectOption('Entertainment', 'entertainment'),
    new SelectOption('Other', 'other'),
  ]

  public get records_text(): string {
    if (this.records === 0) {
      return ''
    }
    let start = (this.currentPage - 1) * this.perPage + 1
    let end = this.currentPage * this.perPage
    return `${start} - ${end} of ${this.records}`
  }

  @Watch('perPage')
  public onPerPageChange() {
    this.currentPage = 1
    this.loadData()
  }

  public mounted() {
    this.loadData()
  }

  public filterNotes(notes) {
    return notes.filter(f => !f.delete_on_save)
  }

  public loadData() {
    this.loading = true

    return OpportunityActivity.paginate({
      page_size: this.perPage,
      page: this.currentPage,
      order_by: this.sortBy,
      order: this.sortDesc ? 'desc' : 'asc',
      query: [`${this.target}:${this.targetValue}`],
      mode: this.filter_mode,
    })
      .then(result => {
        this.local_value = result.data
        this.records = result.records
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  public changePage(page: number) {
    this.currentPage = page
    this.loadData()
  }

  public toggleActivityEdition() {
    if (this.is_creating) {
      this.cancelActivityEdition(0)
      return
    }
    this.is_creating = !this.is_creating
    this.temp_activity.scheduled_at = moment().format('YYYY-MM-DD HH:mm:ss')
    // this.temp_activity.created_at = moment().format('YYYY-MM-DD HH:mm:ss')
  }

  public resetTemp() {
    this.temp_activity = new OpportunityActivity()
  }

  public resetTempNote() {
    this.temp_note = {
      name: null,
      content: null,
      type: null,
      temp_id: null,
      delete_on_save: false,
    }
    this.is_editing = false
    this.is_creating = false
  }

  public cancelActivityEdition(tmo: number = 200, index: any = null) {
    if (index !== null) {
      this.local_value[index].edit_note = false
      this.local_value[index].add_note = false
      this.is_editing = false
    }
    setTimeout(() => {
      this.is_creating = false
      this.resetTemp()
    }, tmo)
  }

  public removeActivity(index: number) {
    if (this.local_value[index].id) {
      Vue.set(this.local_value[index], 'delete_on_save', true)
    }
    // const updated = this.local_value.filter((_, i) => i !== index)
    // this.local_value = updated
    this.$root.$emit('bv::hide::tooltip')
    // eslint-disable-next-line no-unused-expressions
    this.UPDATE++
  }

  public editActivity(index: number) {
    const values = { ...JSON.parse(JSON.stringify(this.local_value[index])) }
    this.temp_activity = Object.assign(
      new OpportunityActivity(),
      JSON.parse(JSON.stringify(values)),
    )
    this.local_value[index].edit_note = true
    this.local_value[index].add_note = false

    this.is_editing = true
    this.$root.$emit('bv::hide::tooltip')
  }

  public addActivity(index: any = null) {
    if (index !== null) {
      this.local_value[index] = this.temp_activity
    } else {
      this.local_value.unshift(this.temp_activity)
    }
    this.cancelActivityEdition(300)
  }

  public addTempNote(idx: number) {
    if (this.editing_note_index >= 0) {
      let tmp_note = JSON.parse(JSON.stringify(this.temp_note))
      tmp_note.temp_id = this.randomUUID() + idx
      this.local_value[idx].notes[this.editing_note_index] = tmp_note
      this.editing_note_index = -1
      this.local_value[idx].toggleAddNote()
      this.resetTempNote()
      return
    }
    this.temp_note.temp_id = this.randomUUID() + idx
    this.temp_note.created_at = moment().format('YYYY-MM-DD HH:mm:ss')
    this.local_value[idx].notes.push(JSON.parse(JSON.stringify(this.temp_note)))
    this.local_value[idx].toggleAddNote()
    this.resetTempNote()
  }

  public editNote(activityIndex: number, noteIndex: number) {
    const note = this.local_value[activityIndex].notes[noteIndex]
    this.editing_note_index = noteIndex
    this.temp_note = JSON.parse(JSON.stringify(note))
    this.local_value[activityIndex].add_note = true
    this.is_editing = true
  }

  public deleteNote(activityIndex: number, noteIndex: number) {
    if (this.local_value[activityIndex].notes[noteIndex].id) {
      Vue.set(this.local_value[activityIndex].notes[noteIndex], 'delete_on_save', true)
    } else {
      this.local_value[activityIndex].notes.splice(noteIndex, 1)
    }

    this.UPDATE++
    this.is_editing = false
  }

  public refresh() {
    this.is_editing = false
    this.is_editing = false

    this.loadData()
  }

  public formatContact(contacts: any, single = false) {
    if (single) {
      return `${startCase(contacts.first_name)} ${startCase(contacts.last_name)}`
    }
    // activity.contacts
    let mapped = contacts
      .map((con: any) => `${startCase(con.first_name)} ${startCase(con.last_name)}`)
      .toString()
    if (mapped.includes(',')) {
      // space after comma
      mapped = mapped.replace(/,/g, ', ')
    }
    return mapped
  }
}
