
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import MediaPlanPrintView from '@/pages/Sales/MediaPlan/components/MediaPlanPrintView.vue'
import MediaPlan from '@/models/MediaPlan'

@Component({
  components: {
    MediaPlanPrintView,
  },
})
export default class MainPlanTab extends ViewModel {
  @Prop({ default: null })
  public main_media_plan_id!: any

  @Prop({ default: false })
  public showDelivery!: boolean

  @Watch('main_media_plan_id')
  public onMainMediaPlanIdChanged(val: any) {
    if (val) {
      this.loadMediaPlan()
    } else {
      this.ready = true
    }
  }

  public ready: boolean = false

  public loading: boolean = false

  public media_plan: MediaPlan = new MediaPlan()

  public loadMediaPlan() {
    this.loading = true
    MediaPlan.find(this.main_media_plan_id).then((media_plan: MediaPlan) => {
      this.media_plan = media_plan
      this.loading = false

      this.ready = true
    })
  }
}
