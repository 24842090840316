
import CompanyContactPicker from '@/components/CompanyContactPicker/CompanyContactPicker.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import UserPicker from '@/components/UserPicker/UserPicker.vue'
import ViewModel from '@/models/ViewModel'
import { currencyMask } from '@/models/interface/Masks'
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator'
import { DaysOfWeek, ProductsFull } from '@/models/interface/Common'
import Opportunity from '@/models/Opportunity'
import MediaPlanPicker from '@/pages/Expenses/components/MediaPlanPicker.vue'
import CheckboxInput from '@/components/CheckboxInput/index.vue'

@Component({
  components: {
    CompanyContactPicker,
    CompanyPicker,
    FormInput,
    UserPicker,
    SelectPicker,
    DatePicker,
    MediaPlanPicker,
    CheckboxInput,
  },
})
export default class OpportunityForm extends ViewModel {
  @Prop()
  public value!: any

  public loading: boolean = true

  @Prop({ default: false })
  public disabled!: boolean

  public local_value: Opportunity = new Opportunity()

  public lost_categories: any = Opportunity.option_list.lost_categories

  public lead_sources: any = Opportunity.option_list.lead_sources

  public mrgs: any = Opportunity.option_list.mrgs

  public force_new_business_options = [
    {
      name: 'Auto',
      value: 'auto',
    },
    {
      name: 'Yes',
      value: 'yes',
    },
    {
      name: 'No',
      value: 'no',
    },
  ]

  public opportunity_reason: boolean = false

  @Watch('local_value', { deep: true })
  public onLocalValueChange(val: Opportunity) {
    this.$emit('input', val)
  }

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public get options() {
    return {
      days_of_week: DaysOfWeek,
      opportunities: {
        status: Opportunity.opportunity_status,
        types: ProductsFull,
        sources: [
          {
            name: 'Outbound',
            value: 'outbound',
          },
          {
            name: 'Inbound',
            value: 'inbound',
          },
          {
            name: 'Referral',
            value: 'referral',
          },
        ],
      },
    }
  }

  public created() {
    if (this.value) this.local_value = this.value
  }

  public mounted() {
    if (this.value) this.local_value = this.value
    this.loading = false
  }

  public askReason() {
    this.opportunity_reason = true
  }
}
