
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import ReconciliationGroup from '@/models/ReconciliationGroup'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { clone, identity } from 'lodash'
import ExportMediaPlan from '@/pages/Sales/MediaPlan/components/ExportMediaPlan.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import DataForm from '@/components/DataForm/DataForm.vue'
import { watch } from 'fs'
import WebMessage from '@/models/WebMessage'
import PrePlan from '@/models/PrePlan'
import { tableFields } from '@/models/metadata/MediaPlanMetadata'
import options from '../options'
import formData from '../form'
import MediaPlanBatchEdit from './MediaPlanBatchEdit.vue'
import MediaPlanPrintView from './MediaPlanPrintView.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
    DataForm,
    MediaPlanBatchEdit,
    MediaPlanPrintView,
  },
})
export default class MediaPlanTable extends ViewModel {
  // dataTable Ref
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly batchForm: any

  @Prop()
  public value!: any

  @Prop()
  public opportunity!: any

  @Prop({ default: false })
  public disabled!: any

  @Prop({ default: false })
  public tableOnly!: any

  @Prop({ default: false })
  public disableAddBtn!: any

  @Prop({ default: true })
  public paginate!: any

  @Prop()
  public company!: any

  @Prop({ default: false })
  public showDelivery!: boolean

  @Prop({ default: 'company' })
  public loadBy!: any

  // Target Media Plan during action event
  public media_plan: MediaPlan | any = {}

  // Total Number of records
  public records: number = 0

  // Loading incicator
  public loading: boolean = false

  public busy = true

  // Search Input
  public query: string[] = []

  // Selected rows
  public selected: string[] = []

   public show_filter_helper: boolean = false

  // dataTable field filters
  public fieldFilters: any = {
    created_at: '',
    updated_at: '',
    type: '',
    status: '',
  }

  // table data
  public table_data: MediaPlan[] = []

  // Used to determine which detail view to show
  public detail_mode = 'schedule'

  // Indicates is component is initialized and ready to load data
  public ready = false

  public temp_mediaplan_batch = new MediaPlan({ status: 'pending_review' })

  public temp_batch_tab: number = 0

  public formFields: any = formData

  public batch_item_modal: boolean = false

  public batch_data: any = []

  @Prop()
  public hide_columns!: any

  // Media Plan Module Options (Search options, Action options, etc)
  public get options() {
    return options
  }

  // List of fields, populated on component mount
  public fields: any[] = []

  // Return active fields to the table
  public get show_fields() {
    return this.fields.filter(
      (f: any) => f.show && (!this.hide_columns || !this.hide_columns.includes(f.key)),
    )
  }

  public belongsToId() {
    if (this.loadBy === 'company') {
      if (!this.company) return ''
      let { type } = this.company
      return `${type}_id:${this.company.id}`
    }
    if (this.loadBy === 'opportunity') {
      if (!this.opportunity) return ''
      return `opportunity_id:${this.opportunity.id}`
    }
  }

  public async getMediaPlans(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key]?.toLowerCase()}`)

    this.syncFilters()

    return PrePlan.getMediaPlanListWhereIn({
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      media_plan_ids: this.value || null,
      company_id: this.company.id || null,
      query: [...context.filter, ...field_filters],
    })
      .then(response => {
        this.records = response.records
        this.loading = false
        delete response.result[0].metadata
        this.media_plan = new MediaPlan(response.result[0])

        return response.result
      })
      .catch((error: any) => {
        this.loading = false
      })
  }

  // Return Promise with DataTable data
  public async mediaPlanPaginate(context: any): Promise<any> {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return MediaPlan.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, this.belongsToId()],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      this.media_plan = new MediaPlan(result.data[0])

      if (result.records) {
        this.$emit('disable-edition', true)
      }

      return result.data
    })
  }

  // Redirect user to the create page
  public createMediaPlan() {
    this.$router.push('/app/sales/media_plan')
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {
      created_at: '',
      updated_at: '',
      type: '',
      status: '',
    }
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Initialize vars
  public mounted() {
    this.fields = tableFields
    this.loadFilters()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_plan',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('media_plan').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
      this.busy = false
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_plan',
      data: null,
    })
  }

  // Open Batch export dialog
  public exportMediaPlans() {
    if (this.selected.length === 0) {
      WebMessage.error('Please select at least one Media Plan')
      return
    }
    let Component = Vue.extend(ExportMediaPlan)
    let instance = new Component({
      propsData: {
        media_plans: this.table_data.filter(
          (media_plan: MediaPlan) =>
            typeof media_plan.id === 'string' && this.selected.includes(media_plan.id),
        ),
        mode: 'single',
      },
    })
    instance.$mount()
  }

  public batchEdit() {
    if (this.selected.length === 0) {
      WebMessage.error('Please select at least one Media Plan')
      return
    }
    this.$bvModal.show('batch-edit')
  }

  public addLineItem() {
    this.temp_mediaplan_batch.addLineItem()
  }

  public async batchConfirm() {
    this.loading = true
    this.batch_item_modal = false
    let obj = this.batchForm.buildData()

    // Build the object containing only the selected fields
    let selected_mediaplan_fields = obj.selected.filter((sel: any) => sel.startsWith('mediaplan.'))
    let selected_lineitems_fields = obj.selected.filter((sel: any) => sel.startsWith('lineitem.'))
    let selected_metadata_fields = obj.selected.filter((sel: any) => sel.startsWith('metadata.'))

    let selected: any = this.table_data.filter((mediaplan: any) =>
      this.selected.includes(mediaplan.id))
    // map deep in the mediaplan object
    selected = selected.map((mediaplan: any) => {
      // root mediaplan object format
      selected_mediaplan_fields.forEach((prop: any) => {
        const path = prop.replaceAll('mediaplan.', '').split('.')

        if (path.length === 1) {
          mediaplan[path[0]] = obj.media_plan[path[0]]
        }
        if (path.length === 2) {
          mediaplan[path[0]][path[1]] = obj.media_plan[path[0]][path[1]]
        }
        if (path.length === 3) {
          mediaplan[path[0]][path[1]][path[2]] = obj.media_plan[path[0]][path[1]][path[2]]
        }
      })
      // mediaplan.metadata object format
      selected_metadata_fields.forEach((prop: any) => {
        const path = prop.replaceAll('metadata.', '').split('.')

        if (path.length === 1) {
          mediaplan.metadata[path[0]] = obj.media_plan.metadata[path[0]]
        }
        if (path.length === 2) {
          mediaplan.metadata[path[0]][path[1]] = obj.media_plan.metadata[path[0]][path[1]]
        }
        if (path.length === 3) {
          mediaplan.metadata[path[0]][path[1]][path[2]] = obj.media_plan.metadata[path[0]][path[1]][path[2]]
        }
      })
      // mediaplan.lineitems[] object format
      mediaplan.line_items = mediaplan.line_items.map((item: any) => {
        selected_lineitems_fields.forEach(prop => {
          const path = prop.replaceAll('lineitem.', '').split('.')

          if (path.length === 1) {
            item[path[0]] = obj.media_plan.line_items[0][path[0]]
          }
          if (path.length === 2) {
            item[path[0]][path[1]] = obj.media_plan.line_items[0][path[0]][path[1]]
          }
          if (path.length === 3) {
            item[path[0]][path[1]][path[2]] = obj.media_plan.line_items[0][path[0]][path[1]][path[2]]
          }
        })

        return item
      })

      return mediaplan
    })

    MediaPlan.batchEdit(selected)
      .then(response => {
        WebMessage.success(
          'Batch update complete! Please review the changes to ensure that it was applied correctly to the media plans.',
        )
        setTimeout(() => {
          this.$bvModal.hide('batch-edit')
          this.loading = false
        }, 2000)
        setTimeout(() => {
          this.refresh()
        }, 2500)
      })
      .catch(error => {
        this.loading = false
      })
  }
}
