var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loading)?[_vm._t("body",null,null,{ history: _vm.history }),(!_vm.$slots['body'] && _vm.history.length)?[(_vm.history && _vm.history.length)?_c('div',{staticClass:"vertical-timeline vertical-timeline--animate vertical-timeline--one-column pt-2 mt-4"},_vm._l((_vm.history),function(hist,histIndex){return _c('div',{key:`hist-${histIndex}-${hist.key}`,staticClass:"vertical-timeline-item vertical-timeline-element pb-4"},[_c('div',[_vm._m(0,true),_c('div',{staticClass:"vertical-timeline-element-content bounce-in"},[_c('div',{staticClass:"d-flex align-items-center mb-4"},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"timeline-title mb-0"},[_vm._v(" "+_vm._s(hist.user)+" - "+_vm._s(hist.event)+" : "+_vm._s(hist.auditable_type)+" ")]),_c('span',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`toggle-${histIndex}-${hist.key}`),expression:"`toggle-${histIndex}-${hist.key}`"}],staticClass:"text-info ml-4 pointer"},[_vm._v(" View Changes")])]),_c('span',[_vm._v(_vm._s(hist.payload.length)+" Changes")])])]),_vm._l((hist.payload),function(data,index){return _c('b-collapse',{key:`collapse-${hist.changed_at}-${hist.user}-${data.key}`,attrs:{"id":`toggle-${histIndex}-${hist.key}`}},[_vm._v(" "+_vm._s(data.is_nested)+" "),(
                    !data.is_nested &&
                      !Array.isArray(data.old) &&
                      !Array.isArray(data.new) &&
                      (typeof data.old !== 'object' || typeof data.new !== 'object')
                  )?_c('span',[(hist.event === 'updated')?_c('p',[_vm._v(" Changed "),_c('span',{staticClass:"font-weight-bold text-capitalize"},[_vm._v(_vm._s(_vm.clear(data.key))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseData({ data: data.old, key: data.key, history: hist }))}}),_vm._v(" to "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseData({ data: data.new, key: data.key, history: hist }))}})]):_c('p',[_c('span',{staticClass:"text-capitalize font-weight-bold"},[_vm._v(_vm._s(_vm.clear(data.key))+" ")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseData({ data: data.new, key: data.key, history: hist }))}})])]):_c('span',{domProps:{"innerHTML":_vm._s(_vm.parseData({ data, key: data.key, nested: true, history: hist }))}}),(index === hist.payload.length - 1)?_c('hr'):_vm._e()])}),_c('span',{staticClass:"vertical-timeline-element-date"},[_vm._v(" "+_vm._s(_vm._f("moment")(hist.changed_at,'MM/DD/YY'))+" "),_c('br'),_vm._v(" at: "+_vm._s(_vm._f("moment")(hist.changed_at,'HH:mm'))),_c('br')])],2)])])}),0):_vm._e(),_c('div',{staticClass:"mt-2 d-flex justify-content-center"},[_c('b-pagination',{attrs:{"total-rows":_vm.records,"per-page":_vm.per_page},on:{"page-click":function($event){return _vm.refresh(300)}},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]:(!_vm.$slots['body'] && !_vm.history.length && !_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center align-content-center",staticStyle:{"min-height":"50vh"}},[_c('div',[_vm._v("No history found")])]):_vm._e()]:_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"min-height":"50vh"}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-4",attrs:{"label":"Loading...","variant":"primary"}}):_vm._e()],1)],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"vertical-timeline-element-icon bounce-in"},[_c('i',{staticClass:"badge badge-dot badge-dot-xl badge-success"})])
}]

export { render, staticRenderFns }