
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import { currencyMask } from '@/models/interface/Masks'
import { Component, Prop, Vue } from 'vue-property-decorator'
import GeoTargetting from '@/pages/LineItem/components/Targetting/GeoTargetting.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'
import DemoTargetBuilder from '@/components/DemoTargetBuilder/DemoTargetBuilder.vue'
import ModelFileUploader from '@/components/ModelFile/ModelFileUploader.vue'
import PlanRequestModule from '@/models/PlanRequest'

@Component({
  components: {
    CompanyPicker,
    FormInput,
    GeoTargetting,
    DatePicker,
    MediaPackagePicker,
    DemoTargetBuilder,
    ModelFileUploader,
  },
})
export default class PlanRequest extends Vue {
  @Prop()
  public requestModel!: any

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: false })
  public managing!: boolean

  public loading: boolean = false

  public automated_files: any = []

  public temp_task: PlanRequestModule = new PlanRequestModule()

  public modal: boolean = false

  public get masks() {
    return {
      currency: currencyMask,
    }
  }

  public get modalTitle() {
    if (!this.temp_task.type) return ''
    let title: any = {
      media_plan: 'Media Plan',
      pre_plan: 'Max Avails Plan',
    }
    let s = title[this.temp_task.type]
    return `Request ${s}`
  }

  public mounted() {}

  public openModal(type: string) {
    let {
      agency_id, advertiser_id, amount, id, account_manager_id,
    } = this.requestModel

    this.$emit('update:managing', true)

    this.temp_task = Object.assign(new PlanRequestModule(), {
      id,
      agency_id,
      advertiser_id,
      amount,
      type,
      account_manager_id,
    })

    this.modal = true
  }

  public requestPlan() {
    this.loading = true
    this.temp_task.save().then(response => {
      this.$emit('update:managing', false)
      this.$emit('refresh', true)

      //   console.log('the response of the request plan', response)
      this.loading = false
      this.modal = false
    })
  }
}
