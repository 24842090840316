
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DataTable from '@/components/DataTable/index.vue'

import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'
import { currencyMask } from '@/models/interface/Masks'
import Invoice from '@/models/Invoice'
import WebMessage from '@/models/WebMessage'
import IconAction from '@/components/IconAction/IconAction.vue'

import {
  clone as _clone, isArray, isEqual, isObject, isString, transform,
} from 'lodash'
import moment from 'moment'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import home_table_options from '../home-table-fields'
import InvoiceBadgeStatus from './InvoiceBadgeStatus.vue'

@Component({
  components: {
    Widget,
    DataTable,
    IconAction,
    InvoiceBadgeStatus,
    DatePicker,
  },
})
export default class InvoiceSimpleTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop()
  public company!: any

  @Prop()
  public extraFieldFilters!: any // adds extra filters to the FieldFilters

  @Prop()
  public extraQuery!: any // adds extra query to the query

  public show_filter_helper: boolean = false

  public page_size: number = 25

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public selected: string[] = []

  public fields: any[] = []

  public fieldFilters: any = {}

  public query: string[] = []

  public invoices: Invoice[] = []

  public invoice: Invoice | any = {}

  public order_type = 'all'

  public download_formats: string[] = ['pdf', 'txt']

  public mark_as_sent: boolean = false

  public close_books_date: string = moment().subtract(1, 'month').format('YYYY-MM')

  public invoice_index: number = 0

  public temp_invoice: any = {
    pay_amount: 0,
    due: 0,
  }

  public action_acknowledged: boolean = false

  public get current_books_closed_date(): any {
    const system = getModule(SystemtModule)

    if (
      system.env_vars.finance
      && system.env_vars.finance.accounting
      && system.env_vars.finance.accounting.closed_books_date
    ) {
      return moment(system.env_vars.finance.accounting.closed_books_date)
        .endOf('day')
        .format('YYYY-MM-DD')
    }

    return null
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get hasLinearInvoices() {
    return this.selected.some((id: string) => {
      const invoice = this.invoices.find((invoice: Invoice) => invoice.id === id)
      return invoice && invoice.isLinear
    })
  }

  public get activeInvoice(): Invoice {
    let ret = this.invoices.find((invoice: Invoice) => invoice.id === this.selected[0])
    if (!ret) {
      ret = new Invoice()
    }
    return ret
  }

  public get masks() {
    return { currencyMask }
  }

  public mounted() {
    this.fields = home_table_options
    this.loadFilters()
  }

  public get hasSelectedUnapprovedInvoices(): boolean {
    return this.selected.some((id: string) => {
      const invoice = this.invoices.find((invoice: Invoice) => invoice.id === id)
      return invoice && invoice.review_status !== 'approved'
    })
  }

  public editInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}`)
  }

  public viewInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}/view`)
  }

  public runPanic() {
    return this.panic()
  }

  public runPanicSend() {
    return this.panicSend()
  }

  private targets: any = {}

  public closeBooks() {
    this.$bvModal.show('invoice-close-books-modal')
  }

  private isQuickbooksLinked() {
    if (!this.is_quickbooks_connected) {
      WebMessage.error(
        'You need to connect your Quickbookks account before performing this action.',
        [
          {
            text: 'Connect Now!',
            action: (toast: any) => {
              this.$router.push({ name: 'Account' })
              WebMessage.hide(toast.id)
            },
          },
        ],
      )
      return false
    }

    return true
  }

  public confirmcloseBooks() {
    this.$bvModal.hide('invoice-close-books-modal')
    Invoice.closeBooks(
      moment(`${this.close_books_date}-01`).endOf('month').format('YYYY-MM-DD'),
    ).then((result: any) => {
      if (result) {
        WebMessage.success('Books closed successfully!')
      }
    })
  }

  public deleteInvoice() {
    if (!this.isQuickbooksLinked()) return

    this.invoice.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public confirmDelete(invoice: Invoice) {
    if (!this.isQuickbooksLinked()) return
    this.invoice = invoice

    WebMessage.confirm(
      `Are you sure that you want to delete the invoice "<strong>${invoice.name}</strong>"? You won't be able to restore it!`,
      'Delete Invoice',
    ).then((value: boolean) => {
      if (value) {
        this.deleteInvoice()
        this.dataTable.refresh()
      }
    })
  }

  public async panicSend() {
    let proceed = await WebMessage.confirm(
      'Are you sure that you want to send all the invoices?.',
      'Send Invoices?',
      { okTitle: 'Send Them all!' },
    )
    if (!proceed) {
      return
    }
    let groups: any = {}
    for (let id in this.targets) {
      if (!groups[this.targets[id]]) {
        groups[this.targets[id]] = []
      }
      groups[this.targets[id]].push(id)
    }

    for (let type in groups) {
      await Invoice.send(groups[type], type)
    }
  }

  public async panic() {
    if (this.targets) {
      let batch = false
      let batch_count = 0
      for (let id in this.targets) {
        let original = await Invoice.find(id)
        if (!original) {
          WebMessage.error(`Invoice #${id} not found.`)
          return
        }

        let invoice = _clone(original)
        invoice = await invoice.rebuildInvoice('single', original.group_mode)
        invoice.id = _clone(original.id)
        invoice.number = _clone(original.number)

        let proceed = false
        if (batch) {
          proceed = true
        } else {
          proceed = await WebMessage.confirm(
            `Should we proceed with the invoice fix for invoice #${invoice.number}? It will update the amounts from ${original.total} to ${invoice.total}.`,
            'Fix Invoice',
            { okTitle: 'Fix' },
          )
        }

        if (proceed) {
          if (!batch && batch_count >= 10) {
            batch_count = 0
            batch = await WebMessage.confirm('Should we batch fix all from now on?', 'Batch Fix', {
              okTitle: 'Yes, run Batch',
            })
          }
          await invoice.save()
          batch_count++
          WebMessage.success(`Invoice ${invoice.invoice_number} has been fixed.`)
        } else {
          break
        }
      }
    }
  }

  public belongsToId() {
    // leave this commented, so in case something breaks we can uncomment it
    // let { type } = this.company
    // return `${type}_id:${this.company.id}`

    return `client_id:${this.company.id}`
  }

  public invoiceRows(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    let q: any = []

    if (this.company) {
      q = q.concat(this.belongsToId())
    }

    return Invoice.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, ...q],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.ready = true
      return result.data
    })
  }

  public requestApproval(invoice: Invoice) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }

    if (invoice && invoice.id) this.selected = [invoice.id]

    WebMessage.confirm(
      this.selected.length === 1
        ? `Are you sure that you want to send the invoice "<strong>#${this.activeInvoice.number} ${this.activeInvoice.name}</strong>" for approval?`
        : 'Are you sure that you want to request approval for the selected invoices?',
      'Request Approval',
      {
        okTitle: 'Request Approval',
      },
    ).then((value: boolean) => {
      if (value) {
        Invoice.requestApproval(this.selected).then(() => {
          this.dataTable.refresh()
          WebMessage.success('Approval request sent!')
        })
      }
    })
  }

  public async recalculateInvoice(row: Invoice, index: number) {
    this.loading = true
    this.$root.$emit('bv::show::modal', 'invoice-print')
    this.invoice_index = index

    let copied_invoice: Invoice = _clone(this.invoices[index])
    copied_invoice = await copied_invoice.rebuildInvoice('single', copied_invoice.group_mode)
    copied_invoice.id = _clone(this.invoices[index].id)
    copied_invoice.number = _clone(this.invoices[index].number)
    copied_invoice.name = _clone(this.invoices[index].name)

    this.loading = false
    if (copied_invoice._hash !== this.invoices[index]._hash) {
      this.temp_invoice = copied_invoice
      this.$root.$emit('bv::show::modal', 'invoice-print')
    } else {
      WebMessage.info(
        'Looks like nothing has changed. Please review the Media Plans and try again.',
      )
    }
  }

  public recalculateAgencyCommission(invoice: Invoice) {
    if (!invoice) {
      if (this.selected.length === 0) {
        WebMessage.error('Please select at least one invoice to send')
        return
      }
      let periods = [
        ...new Set(
          this.invoices
            .filter((i: Invoice) => i.id && this.selected.includes(i.id))
            .map((i: Invoice) => moment(i.created_at).format('YYYY-MM')),
        ),
      ]

      if (periods.length > 1) {
        WebMessage.error('Please select invoices from the same period')
        return
      }
    } else if (invoice.id) {
      this.selected = [invoice.id]
    }

    WebMessage.confirm(
      this.selected.length === 1
        ? `Are you sure that you want to recalculate the agency commission for the invoice "<strong>#${this.activeInvoice.number} ${this.activeInvoice.name}</strong>"?`
        : 'Are you sure that you want to recalculate the agency commission for the selected invoices?',
      'Recalculate Agency Commission',
      {
        okTitle: 'Recalculate',
      },
    ).then((value: boolean) => {
      if (value) {
        this.loading = true
        Invoice.recalculateAgencyCommission(this.selected)
          .then(() => {
            this.loading = false
            setTimeout(() => {
              this.dataTable.refresh()
            }, 500)
            WebMessage.success(
              'Recalculation complete! Please review the invoices before sending to the client to ensure that all the information is correct.',
            )
          })
          .catch((error: any) => {
            WebMessage.error(
              "We couldn't recalculate the agency commission. Please try again later.",
            )
          })
      }
    })
  }

  public markAsSent(invoice: Invoice | null = null) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    this.action_acknowledged = false
    this.mark_as_sent = true
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'txt']
    this.order_type = 'all'

    this.$bvModal.show('send-invoice-modal')
  }

  public registerPayment(item: any) {
    if (!this.isQuickbooksLinked()) {
      return
    }

    this.temp_invoice = item
    setTimeout(() => {
      this.$bvModal.show('payment-modal')
    }, 100)
  }

  public sendInvoices(invoice: Invoice | null = null) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    this.action_acknowledged = false
    this.mark_as_sent = false
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'txt']
    this.order_type = 'all'

    this.$bvModal.show('send-invoice-modal')
  }

  public confirmSendInvoices() {
    if (this.hasSelectedUnapprovedInvoices && !this.action_acknowledged) {
      WebMessage.warning(
        'You have selected invoices that are not approved. Please review them before sending.',
      )
      return
    }
    Invoice.send(this.selected, this.order_type, this.mark_as_sent).then(() => {
      this.dataTable.refresh()
      WebMessage.success(
        'Invoices added to the email queue, the client should get the invoices shortly',
      )
    })
  }

  public download(invoice: Invoice) {
    if (!invoice && this.selected.length === 0) {
      WebMessage.error('Please select at least one invoice to send')
      return
    }
    if (invoice && invoice.id) this.selected = [invoice.id]

    this.download_formats = ['pdf', 'files']
    if (this.hasLinearInvoices) {
      this.download_formats = ['linear_pdf', 'txt', 'files']
      this.order_type = 'all'
    }

    this.$bvModal.show('download-invoice-modal')
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('invoices').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }

      if (this.extraFieldFilters && Object.keys(this.extraFieldFilters).length) {
        this.fieldFilters = { ...this.fieldFilters, ...this.extraFieldFilters }
      }

      if (this.extraQuery && this.extraQuery.length) {
        if (!this.query) this.query = []
        this.query = this.query.concat(this.extraQuery)
      }

      setTimeout(() => {
        this.ready = true
      }, 200)
    })
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'invoices',
      data: null,
    })
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.dataTable.refresh()
  }
}
