
import IconAction from '@/components/IconAction/IconAction.vue'
import ViewModel from '@/models/ViewModel'
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import DataTable from '@/components/DataTable/index.vue'
import PrePlan from '@/models/PrePlan'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import Widget from '@/components/Widget/Widget.vue'
import pre_plan_home_fields from '../pre-plan-home-fields'

@Component({
  components: {
    IconAction,
    DataTable,
    Widget,
  },
})
export default class PrepPlanTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Prop()
  public hide_columns!: any

  @Prop({ default: [] })
  public preplan_ids: any

  @Prop()
  public company!: any

  @Prop()
  public opportunity!: any

  @Prop({ default: false })
  public paginate!: boolean

  @Prop({ default: false })
  public disabled!: boolean

  @Prop({ default: 'company' })
  public loadBy!: any

  public fields: any = pre_plan_home_fields

  public selected: any = []

  public query: string[] = []

  public records: number = 0

  public fieldFilters: any = {}

  public ready: boolean = true

  public busy: boolean = false

  @Watch('selected')
  public onSelectedChange(val: any) {
    if (val) this.$emit('update:preplan_ids', val)
  }

  public mounted() {}

  public async rows(context: any) {
    if (this.paginate) {
      return this.preplans(context)
    }
    return this.getPreplans(context)
  }

  public createNewPlan() {
    this.$router.push({ name: 'preplan' })
  }

  public get table_fields() {
    return this.fields.filter(
      (f: any) => f.show && (!this.hide_columns || !this.hide_columns.includes(f.key)),
    )
  }

  public async getPreplans(context: any) {
    this.loading = true

    if (!this.preplan_ids.length) {
      this.loading = false
      return []
    }

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key]?.toLowerCase()}`)

    this.syncFilters()

    return PrePlan.getListWhereIn({
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      preplan_ids: this.preplan_ids,
      query: [...context.filter, ...field_filters, this.belongsToId()],
    })
      .then(response => {
        this.records = response.records
        this.loading = false
        return response.result
      })
      .catch((error: any) => {
        this.loading = false
      })
  }

  public belongsToId() {
    if (this.loadBy === 'company') {
      if (!this.company) return ''
      let { type } = this.company
      return `${type}_id:${this.company.id}`
    }
    if (this.loadBy === 'opportunity') {
      if (!this.opportunity) return ''
      return `opportunity_id:${this.opportunity.id}`
    }
  }

  public async preplans(context: any) {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key]?.toLowerCase()}`)

    this.syncFilters()

    return PrePlan.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters, this.belongsToId()],
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'preplan',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('preplan').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'preplan',
      data: null,
    })
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.refresh()
  }
}
