
import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator'

import { querySettings } from '@/models/metadata/TaskMetadata'

import Task from '@/models/Task'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import { clone } from 'lodash'
import TaskComments from '@/pages/Task/components/TaskComments.vue'
import CustomIcon from '@/components/CustomIcon/CustomIcon.vue'
import TaskOwnerSelector from '@/pages/Task/components/TaskOwnerSelector.vue'
import StatusSelector from '@/pages/Task/components/StatusSelector.vue'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
    TaskComments,
    CustomIcon,
    StatusSelector,
    TaskOwnerSelector,
  },
})
export default class TaskTable extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly commentSideBar!: TaskComments

  @Prop()
  public extraQuery!: any

  public task: Task = new Task()

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public query: string[] = []

  public selected: string[] = []

  public fieldFilters: any = {
    due_at: '',
    completed_at: '',
    created_at: '',
    updated_at: '',
    status_name: '',
    type: '',
  }

  public table_data: Task[] = []

  public fields: Array<any> = []

  public get show_fields() {
    return this.fields
      .filter((f: any) => f.show)
      .map(m => {
        if (m.key === 'action') {
          if (this.$route.name?.includes('opportunity')) {
            m.actions = m.actions.filter(f => f.event !== 'sub-task')
          }
        }
        return m
      })
  }

  public get query_settings() {
    return querySettings
  }

  public mounted() {
    this.fields = clone(Task.tableFields)
    this.loadFilters()
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return Task.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {
      due_at: '',
      completed_at: '',
      created_at: '',
      updated_at: '',
      status_name: '',
      type: '',
    }
    if (this.extraQuery && this.extraQuery.length) {
      this.query = []
      this.query = this.query.concat(this.extraQuery)
    } else {
      this.query = []
    }
    this.clearFilters()
    this.refresh()
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'task-table',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('task-table').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }

      if (this.extraQuery && this.extraQuery.length) {
        if (!this.query) this.query = []
        this.query = this.query.concat(this.extraQuery)
      }

      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'task-table',
      data: null,
    })
  }

  public triggerAction(event: string, item: any) {
    this.dataTable.triggerAction(event, item)
  }

  public previewComments(task: Task) {
    this.commentSideBar.cancel()
    this.commentSideBar.open = false
    this.task = task
    setTimeout(() => {
      this.commentSideBar.open = true
    }, 500)
  }
}
