import SelectOption from '@/models/interface/SelectOption'

export default {
  query_settings: {
    company_fields: [
      {
        name: 'agency',
        key: 'agency_id',
        type: 'agency',
        description: 'Filter by Agency',
      },
      {
        name: 'station',
        key: 'station_id',
        type: 'station',
        description: 'Filter by Station',
      },
      {
        name: 'advertiser',
        key: 'advertiser_id',
        type: 'advertiser',
        description: 'Filter by Advertiser',
      },
    ],
    user_fields: [
      {
        name: 'sm',
        key: 'sales_management_id',
        description: 'Filter by Sales Manager',
      },
      {
        name: 'sr',
        key: 'sales_rep_id',
        description: 'Filter by Sales Rep',
      },
      {
        name: 'am',
        key: 'account_manager_id',
        description: 'Filter by Account Manager',
      },
    ],
    custom_fields: [
      {
        name: 'is_not:draft',
        value: 'is_not:draft',
        description: 'Includes only records that are not draft',
      },
      {
        name: 'is:ready',
        value: 'is:ready',
        description: 'Includes only records that are ready',
      },
      {
        name: 'is:potentially_missed',
        value: 'is:potentially_missed',
        description: 'Includes only records that have flight dates this week but it is not confirmed',
      },
    ],
  },

  slide_options: [
    { name: 'Overview', value: 'overview' },
    { name: 'Items Details', value: 'details' },
    { name: 'Distribution', value: 'distribution' },
    { name: 'Media Placement', value: 'placements' },
  ],
  download_options: [
    new SelectOption('Proposal XML', 'xml'),
    new SelectOption('Media Plan (Excel)', 'excel'),
    new SelectOption('Insertion Order / Plan (PDF)', 'io'),
    new SelectOption('Delivery Report', 'delivery_report'),
  ],
  cancel_types: [new SelectOption('Cancel Plan', 'cancel'), new SelectOption('Deal Lost', 'lost')],

  map_options: [
    { name: 'DMA', value: 'dma' },
    { name: 'State', value: 'state' },
  ],

  logo_options: [
    {
      name: 'CBS Sports Network',
      value: 'CBS Sports Network',
    },
    {
      name: 'ESPN',
      value: 'ESPN',
    },
    {
      name: 'ESPN 2',
      value: 'ESPN 2',
    },
    {
      name: 'ESPN 3',
      value: 'ESPN 3',
    },
    {
      name: 'ESPN News',
      value: 'ESPN News',
    },
    {
      name: 'Eleven Sports',
      value: 'Eleven Sports',
    },
    {
      name: 'FS1',
      value: 'FS1',
    },
    {
      name: 'FS2',
      value: 'FS2',
    },
    {
      name: 'Fox Sports Network',
      value: 'Fox Sports Network',
    },
    {
      name: 'MLB',
      value: 'MLB',
    },
    {
      name: 'MLB Network',
      value: 'MLB Network',
    },
    {
      name: 'MLS (Soccer)',
      value: 'MLS (Soccer)',
    },
    {
      name: 'NBA',
      value: 'NBA',
    },
    {
      name: 'NBC Sports',
      value: 'NBC Sports',
    },
    {
      name: 'NCAA - ACC Network',
      value: 'NCAA - ACC Network',
    },
    {
      name: 'NCAA - Big 10 Network',
      value: 'NCAA - Big 10 Network',
    },
    {
      name: 'NCAA - CBS Sports Network',
      value: 'NCAA - CBS Sports Network',
    },
    {
      name: 'NCAA - Longhorn Network',
      value: 'NCAA - Longhorn Network',
    },
    {
      name: 'NCAA - Pac 12',
      value: 'NCAA - Pac 12',
    },
    {
      name: 'NCAA - SEC',
      value: 'NCAA - SEC',
    },
    {
      name: 'NFL',
      value: 'NFL',
    },
    {
      name: 'NFL Network',
      value: 'NFL Network',
    },
    {
      name: 'NHL',
      value: 'NHL',
    },
    {
      name: 'Outdoor Network',
      value: 'Outdoor Network',
    },
    {
      name: 'Outside TV',
      value: 'Outside TV',
    },
    {
      name: 'PGA - Golf Channel',
      value: 'PGA - Golf Channel',
    },
    {
      name: 'Racing America',
      value: 'Racing America',
    },
    {
      name: 'SpartanTV',
      value: 'SpartanTV',
    },
    {
      name: 'Stadium',
      value: 'Stadium',
    },
    {
      name: 'TVG',
      value: 'TVG',
    },
    {
      name: 'TVG2',
      value: 'TVG2',
    },
    {
      name: 'Tennis Channel',
      value: 'Tennis Channel',
    },

    // Legacy
    {
      name: 'BeinSports',
      value: 'BeinSports',
    },
    {
      name: 'Sportsman',
      value: 'Sportsman',
    },
    {
      name: 'ESPN U',
      value: 'ESPN U',
    },
    {
      name: 'World Fishing Network',
      value: 'World Fishing Network',
    },
  ],
}
