
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Task from '@/models/Task'
import RichEditor from '@/components/RichEditor/RichEditor.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import Comment from '@/models/Comment'
import IconAction from '@/components/IconAction/IconAction.vue'

import WebMessage from '@/models/WebMessage'

@Component({
  components: {
    RichEditor,
    FormInput,
    IconAction,
  },
})
export default class TaskComments extends ViewModel {
  @Prop({ required: true })
  public task!: Task

  @Ref() validator!: any

  public loading: boolean = false

  public comment: Comment = new Comment('task-')

  public open: boolean = false

  public commenting: boolean = false

  public comments: Comment[] = []

  @Watch('open')
  public onOpenChange(val: boolean) {
    if (val) {
      this.removePageScroll()
      this.loadComments()
    } else {
      this.removePageScroll('auto')
    }
  }

  public loadComments() {
    this.loading = true
    this.task
      .loadComments()
      .then(response => {
        this.loading = false
        this.comments = response
      })
      .catch(() => {
        this.loading = false
      })
  }

  public toggle() {
    this.open = !this.open
  }

  public deleteComment(comment: any, index: number) {
    this.loading = true
    comment.remove().then(() => {
      WebMessage.success('Comment deleted')

      let updated = this.task

      updated.comment_count--

      this.$emit('update:task', updated)

      this.$emit('saved', true)

      this.loadComments()
    })
  }

  public editComment(comment: Comment) {
    this.comment = Comment.toObject({ ...comment })

    this.commenting = true
  }

  public cancel() {
    this.commenting = false

    this.comment = new Comment('task-')
  }

  public saveComment() {
    this.validator.handleSubmit(() => {
      this.loading = true
      if (!this.comment.owner_model_id) {
        this.comment.owner_model_id = this.task.id
        this.comment.owner_model_type = 'task'
      }

      this.comment.task_id = this.task.id

      this.comment
        .save()
        .then(response => {
          let updated = this.task

          response.task_id = this.task.id

          let exists = updated.comments.findIndex(c => c.id === response.id)

          if (exists >= 0) {
            updated.comments[exists] = Comment.toObject({ ...response, base_path: 'task-' })
          } else {
            updated.comments.unshift(Comment.toObject({ ...response, base_path: 'task-' }))

            updated.comment_count++
          }

          this.$emit('update:task', updated)

          this.$emit('saved', true)

          this.loading = false
          this.commenting = false
          this.comment = new Comment('task-')
        })
        .catch(() => {
          this.loading = false
        })
    })
  }

  public createTask(task: Task) {}

  public removePageScroll(state = 'hidden') {
    const html: any = document.querySelector('html')
    html.style.overflowY = state
  }
}
