
import AuditHistory from '@/models/AuditHistory'
import Company from '@/models/Company'
import User from '@/models/User'
import ViewModel from '@/models/ViewModel'
import {
  isArray, isEqual, isNumber, isObject, isString, split, transform,
} from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'

@Component
export default class AuditProvider extends ViewModel {
  @Prop()
  public result_name!: string

  @Prop()
  public table_query!: string

  // main-model
  @Prop({ required: true })
  public mainModel!: any

  @Prop({ required: true })
  public model!: any

  public model_instance: any = null

  public ready: boolean = false

  public history: Array<any> = []

  public per_page: number = 25

  public page: number = 1

  public records: number = 0

  public sort: boolean = false

  public sortBy: string = 'created_at'

  public query: any = []

  public loading: boolean = false

  public format_dictionary: any = {
    status_duration: 'percentage',
    start_at: '[date]MM/DD/YYYY HH:mm a',
    closed_at: '[date]MM/DD/YYYY HH:mm a',
    end_at: '[date]MM/DD/YYYY HH:mm a',
    due_at: '[date]MM/DD/YYYY HH:mm a',
    flight_start: '[date]MM/DD/YYYY HH:mm a',
    flight_end: '[date]MM/DD/YYYY HH:mm a',
    sales_rep_id: 'loader',
    sales_management_id: 'loader',
    owner_id: 'loader',
    owner: 'loader',
    agency_id: 'loader',
    advertiser_id: 'loader',
    station_id: 'loader',
    user_id: 'loader',
    account_manager_id: 'loader',
    amount: 'currency',
    percentage: 'percentage',
    original_amount: 'currency',
    updated_at: '[date]MM/DD/YYYY HH:mm a',
    status_updated_at: '[date]MM/DD/YYYY HH:mm a',
    product_revenue_distribution: 'currency',
    period_revenue_distribution: 'currency',
    monthly_revenue_distribution: 'currency',
    pipeline_value: 'currency',
  }

  public loaded_data: any = {}

  public injectLoadedValue(class_name: string) {
    let el = document.getElementsByClassName(class_name)
    for (let i = 0; i < el.length; i++) {
      el[i].innerText = this.loaded_data[class_name]
    }
  }

  public loaders(id: string, type: string) {
    if (this.loaded_data.hasOwnProperty(`${type}:${id}`)) {
      this.injectLoadedValue(`${type}:${id}`)
      return
    }

    this.loaded_data[`${type}:${id}`] = 'Loading...'

    this.injectLoadedValue(`${type}:${id}`)

    if (
      [
        'sales_rep_id',
        'sales_management_id',
        'user_id',
        'account_manager_id',
        'owner_id',
        'owner',
      ].includes(type)
    ) {
      User.find(id).then((response: any) => {
        this.loaded_data[`${type}:${id}`] = response.name
        this.injectLoadedValue(`${type}:${id}`)
      })
    } else if (['agency_id', 'advertiser_id', 'station_id'].includes(type)) {
      Company.find(id).then((response: any) => {
        this.loaded_data[`${type}:${id}`] = response.name
        this.injectLoadedValue(`${type}:${id}`)
      })
    }
  }

  public formatterList(value: any, key_name: string) {
    if (value === null || value === undefined) return value
    let format_type = this.format_dictionary[key_name]

    if (!format_type) return value
    if (format_type.startsWith('[date]')) {
      let format = format_type.replace('[date]', '')
      value = moment(value).format(format)
      if (value !== 'Invalid date') {
        value = value.toUpperCase()
      } else {
        value = 'N/A'
      }
    } else if (format_type === 'currency') {
      value = numeral(value).format('$0.00')
    } else if (format_type === 'toString') {
      value = JSON.parse(value)
      value = value.join(', ')
    } else if (format_type === 'percentage') {
      value = numeral(value / 100).format('0%')
    }

    return value
  }

  public detectAndFormatValue(value: any, key_name: string = '') {
    if (
      (isString(value) && !value.length)
      || (isArray(value) && !value.length)
      || (isObject(value) && !Object.keys(value).length)
    ) {
      return null
    }

    if (!this.format_dictionary.hasOwnProperty(key_name)) {
      return value === '[]' || value === '{}' ? null : value
    }

    let format_type = this.format_dictionary[key_name]

    if (format_type === 'loader' && value && value.length && value !== 'null') {
      this.loaders(value, key_name)
    }

    return this.formatterList(value, key_name)
  }

  public clear(s: any) {
    if (s === null || s === 'null') return 'N/A'
    if (isString(s)) {
      let ss = s.replaceAll('_', ' ').replaceAll('ids', '').replaceAll('id', '').replace('00:00:00', '')
        || s
      return this.formatModelPath(ss)
    }

    return s
  }

  public valuesDidChange(newValues: any, oldValues: any) {
    // if newvalue is 0 and old is null, return false
    if (
      (newValues === 0 && oldValues === null)
      || (newValues === 'N/A' && oldValues === 'null')
      || (newValues === 'N/A' && oldValues === 'N/A')
      || (newValues === 'null' && oldValues === 'N/A')
      || (newValues === null && oldValues === 0)
      || (!newValues && !oldValues)
    ) {
      return false
    }
    return !isEqual(newValues, oldValues)
  }

  public ignored_keys = ['id', 'status_duration', 'metadata', 'metrics']

  // public ignoreKeys: any = ['status_duration', 'metadata', 'metrics']

  public mounted() {
    this.model_instance = this.model
    this.loadHistory()
  }

  public getChanges(item: any) {
    let direct_values: boolean = false
    try {
      let changes: any = {
        user: item.user?.name,
        event: item.event,
        changed_at: item.created_at,
        payload: [],
        audit_id: item.id,
        auditable_type: this.formatModelPath(item.auditable_type),
      }
      let new_vals = item.new_values.map((item: any) => item.key)
      let old_vals = item.old_values.map((item: any) => item.key)
      let unique_labels = [...new Set([...new_vals, ...old_vals])]
      unique_labels = unique_labels.filter((item: any) => !this.ignored_keys.includes(item))

      // loop unique_labels
      let formated: any = {
        new: {},
        old: {},
      }
      let added_section: any = {}
      for (let label of unique_labels) {
        if (direct_values) {
          const payload = {
            key: label,
            label: this.clear(label),
            new: item.new_values.find((i: any) => i.key === label)?.value ?? null,
            old: item.old_values.find((i: any) => i.key === label)?.value ?? null,
          }

          if (this.valuesDidChange(payload.new, payload.old)) {
            changes.payload.push(payload)
          }
        } else if (label.includes(':')) {
          let splited = label.split(':')
          let section = splited[0]

          // Handle new values
          let currentNew = formated.new
          let currentOld = formated.old

          for (let i = 0; i < splited.length; i++) {
            let part = splited[i]
            if (i === splited.length - 1) {
              currentNew[part] = item.new_values.find((i: any) => i.key === label)?.value ?? null
              currentOld[part] = item.old_values.find((i: any) => i.key === label)?.value ?? null
            } else {
              if (!currentNew[part]) {
                currentNew[part] = {}
              }
              if (!currentOld[part]) {
                currentOld[part] = {}
              }
              currentNew = currentNew[part]
              currentOld = currentOld[part]
            }
          }

          if (!added_section[section]) {
            const payload = {
              key: section,
              event: item.event,
              label: this.clear(section),
              new: formated.new[section],
              old: formated.old[section],
              is_nested: true,
            }
            if (this.valuesDidChange(payload.new, payload.old)) {
              changes.payload.push(payload)
            }
            added_section[section] = true
          }
        } else {
          // If there are no colons, just set the value directly
          formated.new[label] = item.new_values.find((i: any) => i.key === label)?.value ?? 'N/A'
          formated.old[label] = item.old_values.find((i: any) => i.key === label)?.value ?? 'N/A'

          const payload = {
            key: label,
            event: item.event,
            label: this.clear(label),
            new: formated.new[label],
            old: formated.old[label],
          }
          if (label === 'percentage') {
            // check if any percentage is "N/A" or "null" and set to 0
            if (payload.new === 'N/A' || payload.new === 'null') {
              payload.new = 0
            }
            if (payload.old === 'N/A' || payload.old === 'null') {
              payload.old = 0
            }
          }

          let ignore = false
          if (label === 'owner_model_type') {
            ignore = [payload.new ?? '', payload.old ?? ''].some(s =>
              s.toLowerCase().includes(this.mainModel.name.toLowerCase()))
          }

          if (label === 'owner_model_id') {
            ignore = [payload.new ?? '', payload.old ?? ''].some(s => s === this.mainModel.id)
          }

          if (this.valuesDidChange(payload.new, payload.old) && !ignore) {
            changes.payload.push(payload)
          }
        }
      }

      return changes
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error on getChanges', error)
      throw error
    }
  }

  public async loadHistory() {
    try {
      this.loading = true
      const response = await this.model_instance.getHistory({
        page_size: this.per_page,
        page: this.page,
        order_by: this.sortBy,
        order: this.sort ? 'desc' : 'asc',
        query: this.query,
      })

      const results = response.data.result.audits

      const audits = await Promise.all(
        results

          .map((item: any) => this.getChanges(item))
          .filter((item: any) => item.user && item.payload.length > 0),
      )

      this.records = response.data.result.records
      this.history = audits.sort((a, b) => new Date(b.changed_at).getTime() - new Date(a.changed_at).getTime())
    } catch (error) {
      //  eslint-disable-next-line no-console
      console.error('Error on loadHistory', error)
    } finally {
      this.loading = false
    }
  }

  public refresh(tm: number = 0) {
    setTimeout(() => {
      this.loadHistory()
    }, tm)
  }

  public setModel(instance: any) {
    Vue.set(this, 'model_instance', instance)
  }

  public dataToHTML({
    data,
    key,
    nested = false,
    history = null,
    parent_key = null,
  }: {
    data: any
    key?: any
    nested?: boolean
    history?: any
    parent_key?: any
  }): any {
    let list = [
      'sales_rep_id',
      'sales_management_id',
      'agency_id',
      'advertiser_id',
      'user_id',
      'account_manager_id',
      'owner_id',
      'owner',
      'station_id',
    ]

    if (list.includes(key) && data && data !== 'empty' && data !== 'null') {
      this.detectAndFormatValue(data, key)
      return `<span class="text-capitalize ${key}:${data}">${data}</span>`
    }

    // add formatings here, also add extra conditions for formatting for nested objects
    if (parent_key) {
      // this.format_dictionary
      if (parent_key.includes('monthly_revenue_distribution')) {
        if (data === 'N/A') data = 0
        data = this.detectAndFormatValue(data, 'monthly_revenue_distribution')
      } else if (parent_key.includes('period_revenue_distribution')) {
        if (data === 'N/A') data = 0
        data = this.detectAndFormatValue(data, 'period_revenue_distribution')
      } else if (parent_key.includes('product_revenue_distribution')) {
        if (data === 'N/A') data = 0
        data = this.detectAndFormatValue(data, 'product_revenue_distribution')
      }
    }
    data = this.detectAndFormatValue(data, parent_key ?? key)

    if (data === null || data === 'null') data = 'N/A'
    data = this.clear(data)
    return `<span class="text-capitalize">${data}</span>`
  }

  public parseData({
    data,
    key,
    nested = false,
    history = null,
  }: {
    data: any
    key?: any
    nested?: boolean
    history?: any
  }): string {
    // If data is not an object(nested), return the data
    if (!nested) return this.dataToHTML({ data, key, history })

    let elements = ''

    const formatValue = (value: any): string => {
      if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value)
      }
      return String(value)
    }

    const parseObject = (obj: any, parentKey: string, indentLevel: number): string => {
      let result = ''
      for (let [subKey, subValue] of Object.entries(obj)) {
        const fullKey = parentKey ? `${parentKey}.${subKey}` : subKey

        if (typeof subValue === 'object' && subValue !== null) {
          if (!isNaN(subKey)) {
            subKey = '-'
          }
          // Creates a new sub item section that will contain the nested object
          result += `<p class="has-sub-items font-weight-bold text-capitalize ml-${indentLevel}" sub-items="0" section-key="${fullKey}" parent-key="${parentKey}">${this.clear(
            subKey,
          )}</p>`
          result += parseObject(subValue, fullKey, indentLevel + 1)
        } else {
          let oldVal = formatValue(data.old?.[subKey] ?? null)
          let newVal = formatValue(data.new?.[subKey] ?? null)
          // if (newVal !== oldVal) {
          if (newVal !== oldVal) {
            // increment sub-items in the parent to make result html

            newVal = this.dataToHTML({
              data: newVal,
              key: subKey,
              parent_key: parentKey,
              history,
              nested,
            })
            oldVal = this.dataToHTML({
              data: oldVal,
              key: subKey,
              parent_key: parentKey,
              history,
              nested,
            })

            if (!isNaN(subKey)) {
              subKey = '-'
            }

            if (data.event === 'updated') {
              result += `<p class="ml-${indentLevel}" parent-key="${parentKey}" current-key="${subKey}">Changed <span class="font-weight-bold text-capitalize">${this.clear(
                subKey,
              )}</span> ${oldVal} to ${newVal}</p>`
            } else {
              result += `<p class="ml-${indentLevel}" parent-key="${parentKey}" current-key="${subKey}"><span class="font-weight-bold text-capitalize">${this.clear(
                subKey,
              )}</span> ${newVal}</p>`
            }
          }
        }
      }

      return result
    }

    // creates a new section
    elements += `<p class="font-weight-bold text-capitalize main-section has-sub-items" sub-items="0" section-key="${key}"> ${this.clear(
      key,
    )}</p>`
    elements += parseObject(data.new ?? [], key, 1)

    elements = this.updateSubItem(elements)

    return elements
  }

  public formatModelPath(name: string) {
    if (!isString(name)) return name
    return name.replace('App\\Models\\', '')
  }

  public updateSubItem(html) {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    // Count sub items
    // Find all elements with the 'has-sub-items' class
    const sections = doc.querySelectorAll('.has-sub-items')
    sections.forEach(section => {
      let sectionKey = section.getAttribute('section-key')

      if (sectionKey) {
        // Find all direct child elements that match the sectionKey as the parent-key
        const childElements: any = doc.querySelectorAll(`[parent-key="${sectionKey}"]`)
        // Update the sub-items attribute with the number of child elements
        section.setAttribute('sub-items', childElements.length)
      }
    })

    // Search for elements that has sub-items == 0 and check if has parent subtract from it
    const subItems = doc.querySelectorAll('.has-sub-items')
    subItems.forEach(item => {
      // let item_sub_items = item.getAttribute('sub-items')
      let child_key = item.getAttribute('section-key')
      const childElements: any = doc.querySelectorAll(`[parent-key="${child_key}"]`)
      // filter childElements that has sub-items > 0
      const filtered = Array.from(childElements).filter(
        el => el.getAttribute('sub-items') !== '0',
      )
      item.setAttribute('sub-items', filtered.length ?? 0)
    })

    // delete all elements that contains sub-items="0"
    const elements = doc.querySelectorAll('.has-sub-items')
    elements.forEach(element => {
      if (element.getAttribute('sub-items') === '0') {
        element.remove()
      }
    })

    return doc.body.innerHTML
  }
}
