import moment from 'moment'
import ModelFile from './ModelFile'
import Targetting from './BasicTargetting'
import ModelWithFiles from './interface/ModelWithFiles'

export default class PlanRequestModule extends ModelWithFiles {
  protected api_settings = {
    save_mode: 'form',
    paths: {
      singular: 'opportunities/request-plan' as string | null,
      plural: 'opportunities/request-plan' as string | null,
    },
  }

  public name = 'Plan Request'

  public id: any = null // it should be the opportunity id

  public files: ModelFile[] = []

  public agency_id: any = null

  public account_manager_id: any = null

  public advertiser_id: any = null

  public amount: any = 0

  public type: any = 'pre_plan' // media_plan

  public products: any = null

  public flight_start: any = moment().add(1, 'months').startOf('month')

  public flight_end: any = moment(this.flight_start).endOf('month')

  public targetting: Targetting = new Targetting()

  public demo_groups: any = [{ age_low: '25', age_high: '54', target: 'AD' }]

  public set formAmount(val) {
    if (isNaN(val)) this.amount = parseFloat(val.replace(/[^0-9.]/g, '') || 0)
  }

  public get formAmount() {
    if (isNaN(this.amount)) return parseFloat(this.amount?.replace(/[^0-9.]/g, '') || 0)
    return this.amount
  }

  public get apiData() {
    return {
      account_manager_id: this.account_manager_id,
      advertiser_id: this.advertiser_id,
      agency_id: this.agency_id,
      amount: this.formAmount,
      type: this.type,
      products: this.products,
      flight_start: this.flight_start,
      flight_end: this.flight_end,
      targetting: this.targetting,
      demo_groups: this.demo_groups,
    }
  }
}
