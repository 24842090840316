var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"crm-process m-2"},_vm._l((_vm.opportunity_status),function(status){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:status.name,staticClass:"process-item text-white",class:{
          active:
            _vm.value >= status.value && !['lost', 'full_order_cxl'].includes(_vm.lowerCase(status.code)),
          lost:
            ['lost', 'full_order_cxl'].includes(_vm.lowerCase(status.code)) &&
            _vm.lowerCase(status.code) === _vm.lowerCase(_vm.progressStatus),
          lostHover: ['lost', 'full_order_cxl'].includes(_vm.lowerCase(status.code)),
          default:
            ['lost', 'full_order_cxl'].includes(_vm.lowerCase(_vm.progressStatus)) &&
            _vm.lowerCase(status.code) !== _vm.lowerCase(_vm.progressStatus),
        },attrs:{"title":`${status.value}%`,"progress":status.name},on:{"click":function($event){return _vm.updateOnClick(status)}}},[_vm._v(" "+_vm._s(status.name)+" ")])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }